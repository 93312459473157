header {
    .navbar {
        .navbar-brand {
            img.logo {
                width: 180px;
                height: auto;

                @include media-breakpoint-up(sm) {
                    width: 204px;
                }
            }
        }

        .navbar-toggler {
            margin: 0.5rem 0 0.5rem 1rem;
            padding: 0;
            background-color: $black;

            &:focus {
                box-shadow: none;
            }

            .navbar-toggler-icon {
                width: 32px;
                height: 32px;
                background-size: 14px 14px;
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z' fill='%23fff'/%3E%3C/svg%3E");
            }

            &.collapsed {
                .navbar-toggler-icon {
                    background-size: 18px 12px;
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='18' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z' fill='%23fff'/%3E%3C/svg%3E");
                }
            }
        }

        .navbar-nav {
            margin-top: 0.5rem;

            .nav-link {
                font-size: 1.25rem;
                color: $black;
                text-transform: uppercase;
                padding-top: 0.25rem;
                padding-bottom: 0.25rem;

                &.dropdown-toggle {
                    font-weight: bold;
                    padding: 0 0.25rem;
                    border: 2px solid $black;
                    margin-top: 0;
                    margin-left: 1rem;
                }
            }
        }

        .navbar-collapse {
            &.show {
                padding: 0 1rem;

                .nav-item {
                    padding: 0.25rem 0;
                    border-bottom: 1px solid $black;

                    &:first-of-type {
                        border-top: 1px solid $black;
                    }
                }
            }
        }

        .dropdown-menu {
            position: absolute;
            min-width: auto;

            .dropdown-item {
                &.active {
                    font-weight: bold;
                }
            }
        }

        .mobile-lang-navbar-toggler-group {
            display: flex;
        }

        .lang-switch {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            .lang-switch {
                display: block;
            }

            .mobile-lang-switch {
                display: none;
            }
        }
    }
}
