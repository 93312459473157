// https://gwfh.mranftl.com/fonts/roboto-condensed?subsets=latin
/* roboto-condensed-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/roboto-condensed-v25-latin-300.woff2') format('woff2'),
        url('../fonts/roboto-condensed-v25-latin-300.woff') format('woff');
}
/* roboto-condensed-regular - latin */
@font-face {
    font-display: swap;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-condensed-v25-latin-regular.woff2') format('woff2'),
        url('../fonts/roboto-condensed-v25-latin-regular.woff') format('woff');
}
/* roboto-condensed-700 - latin */
@font-face {
    font-display: swap;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/roboto-condensed-v25-latin-700.woff2') format('woff2'),
        url('../fonts/roboto-condensed-v25-latin-700.woff') format('woff');
}
