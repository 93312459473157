.content-slider-container {
    background-color: $micexperts-blue-light;
    padding: 4.5rem 0 2.5rem;
    margin-bottom: 3rem;
}

.content-slider {
    position: relative;
    padding-bottom: 2rem;

    .splide__track {
        margin: 0 3rem;

        @include media-breakpoint-up(md) {
            margin: 0 6rem;
        }
    }

    .splide__arrows {
        .splide__arrow {
            border: 1px solid rgb(33, 33, 33);
            background: transparent;

            svg {
                fill: $black;
                width: 12px;
                height: 12px;
            }

            &:disabled {
                opacity: 1;
                border: 1px solid rgba(33, 33, 33, 0.24);

                svg {
                    fill: rgba(33, 33, 33, 0.24);
                }
            }

            &.splide__arrow--prev {
                left: 0.5em;

                @include media-breakpoint-up(md) {
                    left: 1em;
                }
            }

            &.splide__arrow--next {
                right: 0.5em;

                @include media-breakpoint-up(md) {
                    right: 1em;
                }
            }
        }
    }

    ul.splide__pagination {
        margin-top: 2.25rem;

        li {
            padding-left: 0;
            background-image: none;

            .splide__pagination__page {
                background: transparent;
                border: 1px solid rgba(33, 33, 33, 0.24);

                &.is-active {
                    background: $black;
                    border: 1px solid $black;
                }
            }
        }
    }
}
