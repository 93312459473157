@import '../../node_modules/@splidejs/splide/dist/css/splide.min';

.container-fluid {
    &.container--inspiration {
        max-width: 2000px;
        padding-left: 0;
        padding-right: 0;
    }
}

.header-media {
    position: relative;

    .single-media {
        picture,
        img {
            max-width: 100%;
            min-width: 320px;
        }
    }

    .splide__slide img {
        max-width: 100%;
    }

    picture {
        position: relative;
        display: block;

        &.overlay {
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgba(31, 72, 99, 0.72) 100%);
            }
        }
    }

    .text-overlay {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 2rem;
        z-index: 1;

        @include media-breakpoint-up(sm) {
            left: -0.75rem;
        }
        color: #fff;

        .text-title {
            font-size: 20px;
            line-height: 24px;
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                font-size: 24px;
                line-height: 28px;
            }
        }

        .text-description {
            font-size: 46px;
            line-height: 46px;
            text-transform: uppercase;
            max-width: 100%;

            @include media-breakpoint-up(lg) {
                font-size: 56px;
                line-height: 56px;
                max-width: 730px;
            }
        }
    }
}
