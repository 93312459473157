footer {
    margin-top: 100px;

    .footer-top-links {
        a {
            text-decoration: none;
        }

        .icon-button {
            display: flex;
            flex-direction: row;
            border-top: 1px solid $gray-200;
            padding: 1.5rem 0 1.5rem 0;

            @include media-breakpoint-up(md) {
                &.icon-button--left {
                    border-right: 1px solid $gray-200;
                }

                &.icon-button--right {
                    padding-left: 1.5rem;
                }
            }

            img {
                margin-right: 1.5rem;
            }

            div.content {
                display: flex;
                align-items: center;
            }

            h5 {
                text-transform: uppercase;
                font-weight: 700;
                margin-bottom: 0;
            }

            p {
                margin-bottom: 0;
            }

            span.icon {
                margin-left: auto;
                margin-right: 2rem;
                width: 8px;
                height: auto;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 8px 12px;
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.99997 0L0.589966 1.41L5.16997 6L0.589966 10.59L1.99997 12L7.99997 6L1.99997 0Z' fill='%23212121'/%3E%3C/svg%3E");
            }
        }
    }

    .container-footer-bg {
        background-color: $gray-200;

        .specialists-logo {
            a {
                display: inline-block;
                margin-bottom: 2rem;
            }
        }
    }

    h4 {
        font-size: 20px;
        line-height: 23.44px;
        font-weight: 700;
        text-transform: uppercase;
    }

    p {
        line-height: 1.25;
    }

    .more-links {
        margin-top: 30px;

        .partner-links-group {
            margin-bottom: 1rem;
        }

        .partner-links {
            p:last-of-type {
                margin-bottom: 0;
            }
        }

        .quick-links {
            margin-bottom: 1rem;
        }

        .nav-quicklinks {
            .nav-link {
                padding: 0;
            }
        }

        .accordion {
            .accordion-item {
                background-color: transparent;
                border: none;

                .accordion-header {
                    padding-top: 1.5rem;
                    border-top: 1px solid $micexperts-grey-darker;
                    margin-bottom: 0.5rem;
                }

                .accordion-button {
                    font-size: 20px;
                    font-weight: bold;
                    color: $black;
                    padding: 0 0.5rem 0 0;
                    background-color: transparent;
                    box-shadow: none;
                    text-transform: uppercase;

                    &::after {
                        width: 12px;
                        height: 8px;
                        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.59 0.590027L6 5.17003L1.41 0.590027L0 2.00003L6 8.00003L12 2.00003L10.59 0.590027Z' fill='%23212121'/%3E%3C/svg%3E");
                        background-size: auto;
                    }

                    &:not(.collapsed)::after {
                        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 0L0 6L1.41 7.41L6 2.83L10.59 7.41L12 6L6 0Z' fill='%23212121'/%3E%3C/svg%3E");
                        transform: none;
                    }
                }
            }

            .accordion-body {
                padding: 0;

                p {
                    margin-bottom: 0;

                    a {
                        text-decoration: none;
                        margin: 0 0.5rem;

                        &:first-of-type {
                            margin-left: 0;
                        }
                    }

                    @include media-breakpoint-up(sm) {
                        margin-bottom: 1rem;
                    }
                }
            }

            .accordion-border {
                border-bottom: 1px solid $micexperts-grey-darker;
                margin-top: 1.3rem;
                margin-bottom: 0.5rem;
            }

            @include media-breakpoint-up(sm) {
                .accordion-item {
                    .accordion-header {
                        padding-top: 0;
                        border-top: 0;
                    }

                    .accordion-button {
                        display: block;
                        padding: 0;
                        cursor: auto;

                        &::after {
                            background-image: none;
                        }
                    }

                    .accordion-collapse {
                        display: block;
                    }

                    .accordion-body {
                        padding: 0;
                    }
                }

                .accordion-border {
                    display: none;
                }
            }
        }

        .sm-icons {
            display: flex;
            align-items: center;

            a {
                margin: 0 0.5rem;
            }
        }

        .trust-logos {
            display: flex;
            flex-direction: column;
            margin-top: 2rem;

            a {
                margin-right: 1.5rem;
            }

            .logo-trustedtravelteam {
                max-width: 70px;
                height: auto;
            }

            @include media-breakpoint-up(sm) {
                flex-direction: row;
                align-items: center;
            }

            @include media-breakpoint-up(lg) {
                margin-top: 0;
                justify-content: end;
            }

            img {
                margin-bottom: 0.75rem;
            }
        }
    }

    .bottom-row {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        border-top: 1px solid $micexperts-grey-darker;
        padding-top: 1.5rem;

        li {
            .nav-link {
                padding: 0 1rem;

                &.active {
                    font-weight: $font-weight-bold;
                }
            }

            @include media-breakpoint-up(lg) {
                &:first-of-type {
                    .nav-link {
                        padding-left: 0;
                    }
                }
            }
        }

        .footer-meta-nav {
            .nav {
                justify-content: center;

                a {
                    cursor: pointer;
                }

                @include media-breakpoint-up(lg) {
                    justify-content: left;
                }
            }
        }

        .copyright {
            text-align: center;
            padding-top: 1rem;
            padding-bottom: 1rem;

            @include media-breakpoint-up(lg) {
                text-align: right;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
}
