// -----------------------------------------
// bootstrap variables

// Color system (6)
$white: #fff;
$gray-200: #f1f1f1;
$gray-400: #e4e4e4;
$gray-600: #d1d1d1;
$gray-800: #666;
$black: #212121;

// $primary: #1f4863;
$primary: #212121;
$secondary: #009fe3;

// Options (366)
$enable-dark-mode: false;

// Grid columns (515)
$grid-gutter-width: 30px;

// Components (528)
$border-radius: 0;

// Typography (600)
$font-family-sans-serif: 'Roboto Condensed', sans-serif;
$font-weight-bolder: 700;

// $h1-font-size: $font-size-base * 2.5 !default;  // 40px
// $h2-font-size: $font-size-base * 2 !default;    // 32px
// $h3-font-size: $font-size-base * 1.75 !default; // 28px
// $h4-font-size: $font-size-base * 1.5 !default;  // 24px
// $h5-font-size: $font-size-base * 1.25 !default; // 20px

$headings-font-weight: 400;
$headings-line-height: 1;
$lead-font-size: 18px;
$lead-font-weight: 400;

// Forms (866)
$input-bg: #f1f1f1;
$input-border-color: #f1f1f1;
$input-padding-y: 0.625rem;

// Navbar (1187)
$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0.25rem;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");

// Dropdowns (1233)
$dropdown-border-color: $black;
$dropdown-border-width: 2px;
$dropdown-box-shadow: none;
$dropdown-link-color: $black;
$dropdown-link-hover-color: $black;
$dropdown-link-hover-bg: $white;
$dropdown-link-active-color: $black;
$dropdown-link-active-bg: $white;

// -----------------------------------------
// custom variables

$micexperts-grey-lighter: $gray-200;
$micexperts-grey-light: $gray-400;
$micexperts-grey-dark: $gray-600;
$micexperts-grey-darker: $gray-800;
$micexperts-blue-light: rgba(161, 171, 187, 0.24);
$micexperts-green-light: rgba(209, 214, 157, 0.48);
$micexperts-white-transp: rgba(255,255,255,0.94);
