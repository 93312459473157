.ce-imgtxtjourneyitem {
    margin-bottom: 50px;

    h6 {
        &::after {
            position: absolute;
        }
    }

    .col-text {
        p {
            line-height: 20px;
        }

        p:last-of-type {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(md) {
        .col-text--left {
            h6,
            p {
                text-align: right;
            }
        }
    }

    .col-text--right {
        h6 {
            margin-top: 1rem;
        }

        @include media-breakpoint-up(md) {
            h6 {
                margin-top: 0;
            }
        }

        p:last-of-type {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(md) {
        .col-text--left {
            h6 {
                &::after {
                    right: 0;
                }
            }
        }
    }

    .col-text--center {
        h6 {
            margin-bottom: 12px;

            &::after {
                position: static;
            }
        }

        @include media-breakpoint-up(md) {
            h6,
            p {
                text-align: center;
            }

            h6 {
                &::after {
                    margin: 12px auto 0;
                }
            }
        }
    }
}

.ce-topicbox {
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
        .contact-image {
            margin-right: 2.25rem;
        }
    }

    .textbox-wrap {
        position: relative;
    }

    .textbox {
        padding: 1rem;
        background-color: #fff;
        border: 2px solid $black;
        line-height: 1.25;

        h4 {
            font-weight: normal;
        }

        p:last-of-type {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 180px;

        .textbox {
            position: absolute;
            top: -2.25rem;
            left: 2.25rem;
            right: 0;
            padding: 1.5rem;
        }
    }

    @include media-breakpoint-up(xl) {
        .textbox {
            padding: 2.25rem;
        }
    }
}

.ce-teammember {
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
        .col-image {
            width: 30%;
        }

        .col-text {
            width: 70%;
        }
    }

    .textbox {
        border-top: 1px solid $black;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }

        h4 {
            margin-top: 1rem;

            @include media-breakpoint-up(lg) {
                margin-top: 1.5rem;
            }

            @include media-breakpoint-up(xl) {
                margin-top: 2rem;
            }
        }

        p {
            line-height: 20px;
            margin-bottom: 0;
        }

        h4 + p {
            margin-bottom: 0.5rem;
        }
    }
}

.small-highlight-box {
    background-color: $micexperts-green-light;
    padding: 2.25rem;
    margin-bottom: 2rem;

    &.small-highlight-box--blue {
        background-color: $micexperts-blue-light;
    }

    ul {
        margin-bottom: 0;
    }

    ul + p {
        margin-top: 1rem;
    }

    p:last-of-type {
        margin-bottom: 0;
    }
}

.highlight-box {
    background-color: $micexperts-green-light;
    padding: 4.5rem 1rem 4rem;
    margin-bottom: 3rem;

    &.highlight-box--blue {
        background-color: $micexperts-blue-light;
    }

    @include media-breakpoint-up(md) {
        padding: 4.5rem 6rem 4rem;
    }

    h2,
    h3 {
        margin-bottom: 1.5rem;
    }
}

.container-content {
    .highlight-box {
        ul {
            li {
                padding-left: 1.8rem;
                background-position: left 0;
                background-size: 24px 24px;
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.00039 16.2L4.80039 12L3.40039 13.4L9.00039 19L21.0004 6.99998L19.6004 5.59998L9.00039 16.2Z' fill='%23212121'/%3E%3C/svg%3E");
            }
        }
    }
}
