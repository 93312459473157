.nav-sidebar {
    padding-left: 1.25rem;
    border-left: 2px solid $black;
    margin-bottom: 50px;

    .nav-link {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding: 0.5rem 0 0.5rem 0;
        line-height: 1.25;

        .link-text {
            font-weight: bold;
            text-transform: uppercase;
            padding-right: 0.5rem;

            span {
                font-weight: normal;
                text-transform: none;
            }
        }

        span.icon {
            width: 8px;
            height: auto;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 8px 12px;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.99997 0L0.589966 1.41L5.16997 6L0.589966 10.59L1.99997 12L7.99997 6L1.99997 0Z' fill='%23212121'/%3E%3C/svg%3E");
        }
    }
}

.nav-breadcrumbs {
    align-items: center;
    margin-bottom: 0.25rem;

    li.nav-label {
        display: none;

        @include media-breakpoint-up(sm) {
            padding-right: 1rem;

            & + li.nav-item {
                a {
                    padding-left: 0;
                }
            }
        }

        @include media-breakpoint-up(md) {
            display: inline-block;
        }
    }

    li.nav-item {
        display: none;

        &:nth-last-of-type(2),
        &:nth-last-of-type(2) ~ li {
            display: inline-block;
        }

        @include media-breakpoint-up(sm) {
            display: inline-block;
        }

        &:last-of-type {
            font-weight: bold;
        }

        a {
            display: inline-block;
        }

        & + .nav-item {
            &::before {
                content: ' ';
                display: inline-block;
                width: 8px;
                height: 12px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 8px 12px;
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.99997 0L0.589966 1.41L5.16997 6L0.589966 10.59L1.99997 12L7.99997 6L1.99997 0Z' fill='%23212121'/%3E%3C/svg%3E");
            }
        }
    }
}
