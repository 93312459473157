@import 'content';

@import 'header';
@import 'navs';
@import 'footer';
@import 'fonts';

body {
    min-width: 350px;
}

.container--main {
    margin-top: 50px;
}
