h1,
h2,
h3,
h4 {
    text-transform: uppercase;
}

h1 {
    line-height: 1;
}

h2 {
    line-height: 2.375rem;
}

h3 {
    line-height: 30px;
}

h4 {
    font-weight: bold;
    line-height: 28px;
}

h5 {
    line-height: 24px;
}

h6 {
    position: relative;
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    text-transform: uppercase;
    margin-bottom: 24px;

    &::after {
        content: ' ';
        display: block;
        width: 24px;
        border: 1px solid $black;
        margin-top: 12px;
    }
}

.subheader {
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-transform: uppercase;
}

p.lead {
    font-size: 18px;

    &:last-of-type {
        margin-bottom: 50px;
    }
}

p.testimonial {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 1.5rem;
    font-weight: 300;

    @include media-breakpoint-up(md) {
        font-size: 28px;
        line-height: 30px;
    }
}

a {
    &.btn {
        &.btn-black,
        &.btn-more {
            padding: 0.5rem 1rem;
            background-color: $black;
            color: #fff;
            text-transform: uppercase;

            &:hover {
                color: #fff;
            }
        }

        &.btn-more {
            padding-left: 16px;
            padding-right: 46px;
            background-repeat: no-repeat;
            background-position: right 18px center;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='19' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 4L15 0V3H0V5H15V8L19 4Z' fill='%23ffffff'/%3E%3C/svg%3E");
        }
    }
}

.container-content {
    ul {
        list-style: none;
        padding-left: 0;

        li {
            padding-left: 0.75rem;
            background-position: left 8px;
            background-repeat: no-repeat;
            background-size: 4px 6px;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.99997 0L0.589966 1.41L5.16997 6L0.589966 10.59L1.99997 12L7.99997 6L1.99997 0Z' fill='%23212121'/%3E%3C/svg%3E");
        }
    }

    .nav-sidebar {
        ul {
            li {
                padding-left: 0;
                background-size: 0 0;
                background-image: none;
            }
        }
    }
}

.frame-3col,
.frame-4col {
    img {
        max-width: 100%;
        height: auto;
    }
}

.frame {
    &.frame-mb-small {
        margin-bottom: 2rem;
    }

    &.frame-mb-large {
        margin-bottom: 4rem;
    }
}

// TYPO3 CEs
.ce-intext {
    &.ce-left .ce-gallery {
        margin-right: 30px;
    }

    &.ce-right .ce-gallery {
        margin-left: 30px;
    }

    &.ce-right .ce-gallery,
    &.ce-left .ce-gallery {
        margin-bottom: 30px;
    }
}

@include media-breakpoint-down(sm) {
    .ce-left .ce-gallery,
    .ce-right .ce-gallery {
        float: none;
    }
}
